#faqs {
    background-image: url('https://res.cloudinary.com/ddgxkqi07/image/upload/v1686790864/fondopc2_yhwakn.jpg');
    background-repeat: no-repeat;
    background-position: fixed;
    background-size: cover;
    color: var(--color-white);
}

.titulo {
    color: var(--color-white);
}

.faqs__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 4rem;
    align-items: start;
}

.faq {
    background-color: #303030;
    box-shadow: 2px 2px 8px 2px var(--color-white);
}

.card.faq:hover {
    cursor: pointer;
    background: var(--color-primario);
}

.faq > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

.faq h5 {
    line-height: 1.4;
    color: var(--color-white);
}

.faq__icon {
    font-size: 1.2rem;
    color: var(--color-white);
    background: transparent;
}

.faq__answer {
    margin-top: 1rem;
    opacity: 0;
    animation: animateAnswer 500ms ease-in forwards;
}

@keyframes animateAnswer {
    to {
        opacity: 1;
    }
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .faqs__container {
        grid-template-columns: 1fr;
    }
}


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
.faqs__container {
    gap: 1rem;
}

}