#services{
    background-image: url('https://res.cloudinary.com/ddgxkqi07/image/upload/v1686790864/fondopc_rxs1tz.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: var(--color-white);
}

.titulo{
    color: var(--color-white);
}

.services__container {
    color: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;
}

.card.service {
    background-color: #303030;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    box-shadow: 2px 2px 8px 2px var(--color-white);
}

.service__icon {
    background: var(--color-primario);
    padding: 0.6rem;
    border-radius: var(--border-radius-3);
    font-size: 1.5rem;
    color: var(--color-white);
    transition: var(--transition);
}

.service:hover .service__icon {
    background: var(--color-light);
    color: var(--color-primario);
}

.service h4 {
    margin-bottom: 0.7rem;
    color: var(--color-primario);
}



/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .services__container {
        gap: 1.5rem;
    }
}


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .card.service {
        gap: 1.2rem;
    }
}