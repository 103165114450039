.card.theme__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #dedede;
    z-index: 200;
    text-align: center;
    box-shadow: 0 3rem 3rem rgba(20, 24, 255, 0.3);
    padding: 3rem;
    opacity: 0;
    animation: animateModal 500ms ease-in forwards;
}

.items{
    margin: 10px;
}

.logo {
    display: block;
    margin: auto;
    width: 100px;
}

.subtitulo{
    font-size: 20px;
    margin-bottom: 2%;
}

@keyframes animateModal {
    to {
        opacity: 1;
    }
}

.card.theme__modal:hover {
    background: var(--color-white); 
    color: var(--color-primario);
}

.card.theme__modal small {
    margin-top: 0.6rem;
    display: block;
    width: 80%;
    margin-inline: auto;
}

.theme__primary-wrapper {
    margin: 3rem 0;
}

.theme__primary-colors {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
}



/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .card.theme__modal {
        width: var(--container-width-md);
        padding: 3rem 1.5rem;
    }

    .theme__primary-colors {
        gap: 0.6rem;
    }
}