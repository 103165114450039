.contact__container {
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.img {
    width: 15rem;
    display: block;
    float: left;
    margin-top: -14%;
    margin-left: 8%;
}

.contact__container a {
    width: 5rem;
    aspect-ratio: 1/1;
    color: white;
    background: var(--color-primario);
    display: grid;
    place-items: center;
    font-size: 2rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
}

.contact__container a:hover {
    background: transparent;
    border-color: var(--color-primario);
    color: var(--color-primario);
    transform: translateY(-0.5rem);
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1900px) {
    .img {
        display: none;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .contact__container {
        gap: 1.5rem;
    }

    .img {
        display: none;
    }
}